import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import Box from "@mui/material/Box";
import ProjectHeader from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, keywords} from "../../data/products/radar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid/Grid";
import {
  Box4Title,
  ProductContact,
  SubTitleEnBox,
  SubTitleWhiteBox,
  TitleBox,
  TitleEnBox
} from "../../components/Common/Base";
import Stack from "@mui/material/Stack/Stack";


const City3d = (props) => {
  const { location } = props;

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/radar/01.mp4').default}
        backImg={require('../../images/products/radar/radar01.jpg').default}
        backVideo={'products/radar/radar01.m3u8'}
        subTitle={'三维雷达'}
        title={'实时雷达三维渲染系统'}
        titleEn={'Three-dimensional Radar'}
        titleEn2={'Real-time Radar 3D Rendering System'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/* box1 */}
      <Box
        ref={eleShow}
        sx={{
          pt: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            sx={{

            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                }
              }}
            >
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: "left",
                  },
                }}
              >
                三维雷达实况监测
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: "left",
                  },
                  fontSize: {
                    md: '2vw',
                    lg: '24px',
                  }
                }}
              >
                3D Radar Live Monitoring
              </Box4Title>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                }}
                src={require('../../images/products/radar/01.jpg').default}
                alt='3维雷达--赑玄阁'
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <Stack
                sx={{height: `calc(100% - 8px)`}}
                spacing={2}
                justifyContent="center"
              >
                <Box>
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'block',
                      }
                    }}
                  >
                    <Box4Title
                      variant="h5"
                    >
                      三维雷达实况监测
                    </Box4Title>
                    <TitleEnBox
                      gutterBottom
                      variant="h6"
                    >
                      3D Radar Live Monitoring
                    </TitleEnBox>
                  </Box>
                  <SubTitleWhiteBox
                    variant="h5"
                    gutterBottom
                    sx={{
                      // fontWeight: '100',
                      textAlign: {
                        xs: 'center',
                        sm: 'left',
                      },
                    }}
                  >
                    2D&3D动画可视化展示，实时更新数据。
                  </SubTitleWhiteBox>
                  <SubTitleEnBox
                    gutterBottom
                    variant="h6"
                    sx={{
                      textAlign: {
                        xs: 'center',
                        sm: 'left',
                      },
                      // color: '#fff',
                    }}
                  >
                    2D & 3D animated visual display with real-time data update
                  </SubTitleEnBox>
                </Box>
                {/* <Box
                  component="img"
                  sx={{
                    width: '100%',
                    display: {
                      xs: 'none',
                      sm: 'block',
                    }
                  }}
                  src={require('../../images/products/radar/02.jpg').default}
                  alt='3维雷达--赑玄阁'
                /> */}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <Stack
                sx={{
                  height: `calc(100% - 8px)`,
                  flexDirection: 'column',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                spacing={2}
              >
                <Box>
                  <Box4Title
                    variant="h5"
                    sx={{
                      textAlign: {
                        xs: 'center',
                        sm: 'left',
                      }
                    }}
                  >
                    现实对照场景
                  </Box4Title>
                  <TitleEnBox
                    gutterBottom
                    variant="h6"
                    sx={{
                      textAlign: {
                        xs: 'center',
                        sm: 'left',
                      }
                    }}
                  >
                    Comparison Scenario
                  </TitleEnBox>
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'block',
                      }
                    }}
                  >
                    <SubTitleWhiteBox
                      variant="h5"
                      gutterBottom
                      sx={{
                        // fontWeight: '100',
                        textAlign: {
                          xs: 'center',
                          sm: 'left',
                        },
                      }}
                    >
                      依据物理原理，展现现实天气状况
                    </SubTitleWhiteBox>
                    <SubTitleEnBox
                      gutterBottom
                      variant="h6"
                      sx={{
                        textAlign: {
                          xs: 'center',
                          sm: 'left',
                        },
                        // color: '#fff',
                      }}
                    >
                      Based on physical principles, showing realistic weather conditions
                    </SubTitleEnBox>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            {/* <Grid
              item
              xs={6}
              sm={4}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                }}
                src={require('../../images/products/radar/04.jpg').default}
                alt='3维雷达--赑玄阁'
              />
            </Grid> */}
            {/* <Grid
              item
              xs={6}
              sm={4}
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                }
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                }}
                src={require('../../images/products/radar/02.jpg').default}
                alt='3维雷达--赑玄阁'
              />
            </Grid> */}
            
            <Grid
              item
              xs={12}
              sm={8}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                }}
                src={require('../../images/products/radar/07.jpeg').default}
                alt='3维雷达--赑玄阁'
              />
            </Grid>
            <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: {
                    xs: 'flex',
                    sm: 'none',
                  },
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  gutterBottom
                  sx={{
                    // fontWeight: '100',
                    textAlign: {
                      xs: 'center',
                    },
                    fontSize: '16px',
                  }}
                >
                  依据物理原理，展现现实天气状况
                </SubTitleWhiteBox>
                <SubTitleEnBox
                  gutterBottom
                  variant="h6"
                  sx={{
                    textAlign: {
                      xs: 'center',
                    },
                    // color: '#fff',
                    fontSize: '14px',
                  }}
                >
                  Based on physical principles, showing realistic weather conditions
                </SubTitleEnBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box2 */}
      <Box
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Box4Title
            variant="h5"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            台风结构生成
          </Box4Title>
          <TitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Typhoon Structure Generation
          </TitleEnBox>
          <Box
            component="img"
            sx={{
              width: '100%',
              mt: 2,
              mb: 2,
            }}
            src={require('../../images/products/radar/05.jpg').default}
            alt='3维雷达--赑玄阁'
          />
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            sx={{
              // fontWeight: '100',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            3D动画可视化展示，历史台风回顾
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              },
              // color: '#fff',
            }}
          >
            3D animation visualization display, historical typhoon review
          </SubTitleEnBox>
        </Container>
      </Box>
      {/* box3 */}
      {/* box2 */}
      <Box
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Box4Title
            variant="h5"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            超高清分辨率
          </Box4Title>
          <TitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Ultra High Resolution
          </TitleEnBox>
          <Box
            component="img"
            sx={{
              width: '100%',
              mt: 2,
              mb: 2,
            }}
            src={require('../../images/products/radar/06.jpg').default}
            alt='3维雷达--赑玄阁'
          />
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            sx={{
              // fontWeight: '100',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            2.5m超高清卫星分辨率
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              textAlign: {
                xs: 'center',
              },
              // color: '#fff',
            }}
          >
            2.5m Ultra HD satellite resolution
          </SubTitleEnBox>
        </Container>
      </Box>
      {/* box4 */}
      <ProductContact />
    </Layout>
  )
}


export default City3d;
